import React from 'react';
import { FileText } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { mockDocuments } from './mockData';

export function DocumentList() {
  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900">Documents</h1>
      
      <div className="bg-white shadow-md rounded-lg divide-y divide-gray-200">
        {mockDocuments.map((doc) => (
          <div key={doc.id} className="p-6 flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="p-2 bg-blue-50 rounded-lg">
                <FileText className="h-6 w-6 text-blue-600" />
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-900">{doc.title}</h3>
                <p className="text-sm text-gray-500">
                  {doc.status === 'signed' 
                    ? `Signed on ${new Date(doc.signedAt!).toLocaleDateString()}`
                    : 'Pending signature'}
                </p>
              </div>
            </div>
            <button
              onClick={() => navigate(`/dashboard/documents/${doc.id}`)}
              className="px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-800"
            >
              {doc.status === 'signed' ? 'View Document' : 'Sign Document'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}