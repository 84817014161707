import React, { useRef, useState } from 'react';
import { Button } from '../../../components/ui/Button';
import { Input } from '../../../components/ui/Input';
import { Pen, Type, Undo2 } from 'lucide-react';

interface Props {
  onNext: (data: { signature: string }) => void;
}

export function Signature({ onNext }: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [signatureMode, setSignatureMode] = useState<'draw' | 'type'>('draw');
  const [typedSignature, setTypedSignature] = useState('');
  const [hasSignature, setHasSignature] = useState(false);

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    setIsDrawing(true);
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const rect = canvas.getBoundingClientRect();
    const x = ('touches' in e) ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
    const y = ('touches' in e) ? e.touches[0].clientY - rect.top : e.clientY - rect.top;

    ctx.beginPath();
    ctx.moveTo(x, y);
  };

  const draw = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const rect = canvas.getBoundingClientRect();
    const x = ('touches' in e) ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
    const y = ('touches' in e) ? e.touches[0].clientY - rect.top : e.clientY - rect.top;

    ctx.lineWidth = 2;
    ctx.lineCap = 'round';
    ctx.lineTo(x, y);
    ctx.stroke();
    setHasSignature(true);
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  const clearSignature = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setTypedSignature('');
    setHasSignature(false);
  };

  const handleSubmit = () => {
    if (signatureMode === 'draw') {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const signature = canvas.toDataURL();
      onNext({ signature });
    } else {
      onNext({ signature: typedSignature });
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <h2 className="text-lg font-medium text-gray-900">Your Signature</h2>
        <p className="text-sm text-gray-500">
          Please provide your signature to complete the onboarding process
        </p>
      </div>

      <div className="flex space-x-4">
        <Button
          type="button"
          variant={signatureMode === 'draw' ? 'primary' : 'outline'}
          onClick={() => setSignatureMode('draw')}
        >
          <Pen className="h-4 w-4 mr-2" />
          Draw
        </Button>
        <Button
          type="button"
          variant={signatureMode === 'type' ? 'primary' : 'outline'}
          onClick={() => setSignatureMode('type')}
        >
          <Type className="h-4 w-4 mr-2" />
          Type
        </Button>
      </div>

      {signatureMode === 'draw' ? (
        <div className="space-y-4">
          <div className="border-2 border-gray-300 rounded-lg p-4">
            <canvas
              ref={canvasRef}
              width={560}
              height={200}
              className="w-full border border-gray-300 rounded touch-none bg-white"
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={stopDrawing}
              onMouseLeave={stopDrawing}
              onTouchStart={startDrawing}
              onTouchMove={draw}
              onTouchEnd={stopDrawing}
            />
          </div>
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={clearSignature}
            className="flex items-center"
          >
            <Undo2 className="h-4 w-4 mr-2" />
            Clear
          </Button>
        </div>
      ) : (
        <div className="space-y-4">
          <input
            value={typedSignature}
            onChange={(e) => {
              setTypedSignature(e.target.value);
              setHasSignature(!!e.target.value);
            }}
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 font-handwriting text-xl"
            placeholder="Type your full name"
          />
        </div>
      )}

      <div className="flex justify-end">
        <Button
          onClick={handleSubmit}
          disabled={!hasSignature}
        >
          Complete Onboarding
        </Button>
      </div>

      {!hasSignature && (
        <p className="text-sm text-gray-500 text-center">
          Please provide your signature to continue
        </p>
      )}
    </div>
  );
}