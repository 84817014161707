import { create } from 'zustand';

interface OnboardingState {
  formData: Record<string, any>;
  setFormData: (data: Record<string, any>) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  resetOnboarding: () => void;
}

export const useOnboardingStore = create<OnboardingState>((set) => ({
  formData: {},
  currentStep: 0,
  setFormData: (data) => set((state) => ({
    formData: { ...state.formData, ...data }
  })),
  setCurrentStep: (step) => set({ currentStep: step }),
  resetOnboarding: () => set({ formData: {}, currentStep: 0 })
}));