import React from 'react';
import { CheckCircle } from 'lucide-react';

export function Success() {
  return (
    <div className="text-center">
      <div className="flex justify-center">
        <CheckCircle className="w-16 h-16 text-green-500" />
      </div>
      <h2 className="mt-4 text-2xl font-bold text-gray-900">
        Onboarding Complete!
      </h2>
      <p className="mt-2 text-gray-600">
        Thank you for completing the onboarding process. We've sent you an email with additional information.
      </p>
      <p className="mt-4 text-sm text-gray-500">
        You can now access your dashboard to view and manage your documents.
      </p>
    </div>
  );
}