import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../../../components/ui/Button';
import { Upload } from 'lucide-react';

const schema = z.object({
  idCard: z.instanceof(File).optional(),
  proofOfAddress: z.instanceof(File).optional(),
  socialSecurity: z.instanceof(File).optional(),
});

type FormData = z.infer<typeof schema>;

interface Props {
  onNext: (data: FormData) => void;
}

export function DocumentUpload({ onNext }: Props) {
  const { register, handleSubmit, watch } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const files = watch();

  const renderFileInput = (name: keyof FormData, label: string) => {
    const file = files[name] as File | undefined;
    
    return (
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">{label}</label>
        <div className="flex items-center space-x-4">
          <label className="flex-1">
            <div className="flex items-center justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 rounded-md appearance-none cursor-pointer hover:border-blue-400 focus:outline-none">
              <div className="flex flex-col items-center space-y-2">
                <Upload className="w-8 h-8 text-gray-400" />
                <span className="text-sm text-gray-500">
                  {file ? file.name : 'Click to upload'}
                </span>
              </div>
            </div>
            <input
              type="file"
              className="hidden"
              accept="image/*,.pdf"
              {...register(name)}
            />
          </label>
          {file && (
            <div className="flex items-center justify-center w-32 h-32 bg-gray-100 rounded-md border border-gray-300">
              {file.type && file.type.startsWith('image/') ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt="Preview"
                  className="object-cover w-full h-full rounded-md"
                />
              ) : (
                <div className="flex items-center justify-center text-sm text-gray-500">
                  PDF Document
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onNext)} className="space-y-8">
      <div className="space-y-6">
        {renderFileInput('idCard', 'Government-issued ID')}
        {renderFileInput('proofOfAddress', 'Proof of Address')}
        {renderFileInput('socialSecurity', 'Social Security Card')}
      </div>

      <div className="flex justify-end">
        <Button type="submit">
          Next Step
        </Button>
      </div>
    </form>
  );
}