import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { Download, FileText, Mail, Phone, MapPin } from 'lucide-react';

const mockEmployee = {
  id: '1',
  firstName: 'John',
  lastName: 'Doe',
  email: 'john.doe@example.com',
  phone: '+1 (555) 123-4567',
  address: '123 Main St, Anytown, ST 12345',
  status: 'active',
  startDate: '2024-01-15',
  documents: [
    {
      id: '1',
      name: 'Employee Handbook',
      status: 'signed',
      signedDate: '2024-01-16',
    },
    {
      id: '2',
      name: 'Code of Conduct',
      status: 'signed',
      signedDate: '2024-01-16',
    },
    {
      id: '3',
      name: 'Benefits Enrollment',
      status: 'pending',
    },
  ],
};

export function EmployeeDetails() {
  const { id } = useParams();

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Employee Details</h1>
        <Button variant="outline">
          <Download className="w-4 h-4 mr-2" />
          Export Data
        </Button>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-6">
          <div className="flex items-center space-x-4 mb-6">
            <div className="h-16 w-16 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 text-xl font-semibold">
              {mockEmployee.firstName[0]}{mockEmployee.lastName[0]}
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900">
                {mockEmployee.firstName} {mockEmployee.lastName}
              </h2>
              <p className="text-sm text-gray-500">Employee ID: {id}</p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">Contact Information</h3>
              <div className="space-y-3">
                <div className="flex items-center text-gray-600">
                  <Mail className="w-5 h-5 mr-2" />
                  {mockEmployee.email}
                </div>
                <div className="flex items-center text-gray-600">
                  <Phone className="w-5 h-5 mr-2" />
                  {mockEmployee.phone}
                </div>
                <div className="flex items-center text-gray-600">
                  <MapPin className="w-5 h-5 mr-2" />
                  {mockEmployee.address}
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">Employment Details</h3>
              <div className="space-y-3">
                <div>
                  <span className="text-sm text-gray-500">Status</span>
                  <span className={`ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                    ${mockEmployee.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'}`}>
                    {mockEmployee.status.toUpperCase()}
                  </span>
                </div>
                <div>
                  <span className="text-sm text-gray-500">Start Date</span>
                  <span className="ml-2 text-gray-900">
                    {new Date(mockEmployee.startDate).toLocaleDateString()}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Documents</h3>
            <div className="space-y-4">
              {mockEmployee.documents.map((doc) => (
                <div key={doc.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center">
                    <FileText className="w-5 h-5 text-gray-400 mr-3" />
                    <div>
                      <p className="text-sm font-medium text-gray-900">{doc.name}</p>
                      <p className="text-sm text-gray-500">
                        {doc.status === 'signed' 
                          ? `Signed on ${new Date(doc.signedDate).toLocaleDateString()}`
                          : 'Pending signature'}
                      </p>
                    </div>
                  </div>
                  <Button variant="outline" size="sm">View</Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}