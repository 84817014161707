import { User } from '../../types';

export const mockEmployees: User[] = [
  {
    id: '1',
    firstName: 'John',
    lastName: 'Doe',
    email: 'john.doe@example.com',
    role: 'employee',
    status: 'active',
  },
  {
    id: '2',
    firstName: 'Jane',
    lastName: 'Smith',
    email: 'jane.smith@example.com',
    role: 'employee',
    status: 'pending',
  },
  {
    id: '3',
    firstName: 'Michael',
    lastName: 'Johnson',
    email: 'michael.j@example.com',
    role: 'employee',
    status: 'active',
  },
  {
    id: '4',
    firstName: 'Sarah',
    lastName: 'Williams',
    email: 's.williams@example.com',
    role: 'employee',
    status: 'inactive',
  },
];