import React, { useState } from 'react';
import { FileText, Check, ChevronDown } from 'lucide-react';
import { Button } from '../../../components/ui/Button';

interface Document {
  id: string;
  title: string;
  content: string;
}

const mockDocuments: Document[] = [
  {
    id: '1',
    title: 'Employee Handbook',
    content: 'This handbook outlines our company policies and procedures...',
  },
  {
    id: '2',
    title: 'Code of Conduct',
    content: 'Our code of conduct establishes expected behavior and ethics...',
  },
  {
    id: '3',
    title: 'Benefits Overview',
    content: 'Details about your health insurance, retirement plans, and other benefits...',
  },
];

interface Props {
  onNext: (data: { signedDocuments: string[] }) => void;
}

export function DocumentSigning({ onNext }: Props) {
  const [expandedDoc, setExpandedDoc] = useState<string | null>(null);
  const [signedDocs, setSignedDocs] = useState<string[]>([]);

  const handleSign = (docId: string) => {
    if (!signedDocs.includes(docId)) {
      setSignedDocs([...signedDocs, docId]);
    }
  };

  const handleNext = () => {
    if (signedDocs.length === mockDocuments.length) {
      onNext({ signedDocuments: signedDocs });
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <h2 className="text-lg font-medium text-gray-900">Required Documents</h2>
        <p className="text-sm text-gray-500">
          Please review and sign the following documents
        </p>
      </div>

      <div className="space-y-4">
        {mockDocuments.map((doc) => (
          <div
            key={doc.id}
            className="border rounded-lg overflow-hidden bg-white"
          >
            <div
              className="p-4 flex items-center justify-between cursor-pointer"
              onClick={() => setExpandedDoc(expandedDoc === doc.id ? null : doc.id)}
            >
              <div className="flex items-center space-x-3">
                <FileText className="h-5 w-5 text-blue-600" />
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    {doc.title}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {signedDocs.includes(doc.id) ? (
                      <span className="text-green-600 flex items-center">
                        <Check className="h-4 w-4 mr-1" />
                        Signed
                      </span>
                    ) : (
                      'Pending signature'
                    )}
                  </p>
                </div>
              </div>
              <ChevronDown
                className={`h-5 w-5 text-gray-400 transition-transform ${
                  expandedDoc === doc.id ? 'transform rotate-180' : ''
                }`}
              />
            </div>

            {expandedDoc === doc.id && (
              <div className="p-4 border-t bg-gray-50">
                <div className="prose prose-sm max-w-none">
                  <p className="text-gray-700">{doc.content}</p>
                </div>
                {!signedDocs.includes(doc.id) && (
                  <div className="mt-4 flex justify-end">
                    <Button
                      onClick={() => handleSign(doc.id)}
                      size="sm"
                    >
                      Sign Document
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-end">
        <Button
          onClick={handleNext}
          disabled={signedDocs.length !== mockDocuments.length}
        >
          Next Step
        </Button>
      </div>

      {signedDocs.length !== mockDocuments.length && (
        <p className="text-sm text-gray-500 text-center">
          Please sign all documents to continue
        </p>
      )}
    </div>
  );
}