import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Download, FileText } from 'lucide-react';
import { Button } from '../../components/ui/Button';
import { mockDocuments } from './mockData';
import ReactMarkdown from 'react-markdown';

export function DocumentViewer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const document = mockDocuments.find(doc => doc.id === id);

  if (!document) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900">Document Not Found</h2>
          <Button
            onClick={() => navigate(-1)}
            variant="outline"
            className="mt-4"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Go Back
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-4xl mx-auto">
        <div className="mb-6 flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Button
              onClick={() => navigate(-1)}
              variant="outline"
              size="sm"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back
            </Button>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{document.title}</h1>
              <p className="text-sm text-gray-500">Version {document.version}</p>
            </div>
          </div>
          <div className="flex items-center space-x-3">
            <Button variant="outline" size="sm">
              <Download className="w-4 h-4 mr-2" />
              Download
            </Button>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg">
          <div className="p-6 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <FileText className="w-5 h-5 text-blue-600" />
                <span className="text-sm font-medium text-gray-900">
                  Last updated: {new Date(document.lastUpdated).toLocaleDateString()}
                </span>
              </div>
              <div className={`px-3 py-1 rounded-full text-sm font-medium
                ${document.status === 'signed' 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-yellow-100 text-yellow-800'
                }`}>
                {document.status === 'signed' 
                  ? `Signed on ${new Date(document.signedAt!).toLocaleDateString()}`
                  : 'Pending Signature'
                }
              </div>
            </div>
          </div>
          
          <div className="p-8">
            <div className="prose prose-blue max-w-none">
              <ReactMarkdown>{document.content}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}