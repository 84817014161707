import React from 'react';
import { PersonalInfoForm } from './steps/PersonalInfoForm';
import { DocumentUpload } from './steps/DocumentUpload';
import { DocumentSigning } from './steps/DocumentSigning';
import { Signature } from './steps/Signature';
import { Success } from './steps/Success';
import { useOnboardingStore } from './hooks/useOnboardingStore';
import { Button } from '../../components/ui/Button';
import { ChevronLeft } from 'lucide-react';

const steps = [
  { id: 'personal', title: 'Personal Information' },
  { id: 'documents', title: 'Document Upload' },
  { id: 'signing', title: 'Document Signing' },
  { id: 'signature', title: 'Signature' },
];

export function OnboardingFlow() {
  const { currentStep, setCurrentStep, formData, setFormData } = useOnboardingStore();

  const handleNext = (data: any) => {
    setFormData(data);
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <PersonalInfoForm onNext={handleNext} initialData={formData} />;
      case 1:
        return <DocumentUpload onNext={handleNext} initialData={formData} />;
      case 2:
        return <DocumentSigning onNext={handleNext} initialData={formData} />;
      case 3:
        return <Signature onNext={handleNext} initialData={formData} />;
      case 4:
        return <Success />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-2xl bg-white rounded-xl shadow-lg p-8">
        {currentStep < steps.length && (
          <div className="mb-8">
            {currentStep > 0 && (
              <button
                onClick={handleBack}
                className="flex items-center text-sm text-gray-600 hover:text-gray-900 mb-4"
              >
                <ChevronLeft className="w-4 h-4 mr-1" />
                Back
              </button>
            )}
            
            <div className="flex justify-between items-center mb-4">
              {steps.map((step, index) => (
                <React.Fragment key={step.id}>
                  <button
                    onClick={() => index < currentStep && setCurrentStep(index)}
                    className={`flex items-center ${
                      index <= currentStep
                        ? 'cursor-pointer'
                        : 'cursor-not-allowed'
                    }`}
                    disabled={index > currentStep}
                  >
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        index < currentStep
                          ? 'bg-green-600 text-white'
                          : index === currentStep
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-200'
                      }`}
                    >
                      {index + 1}
                    </div>
                    <span className={`ml-2 text-sm font-medium ${
                      index === currentStep
                        ? 'text-blue-600'
                        : index < currentStep
                        ? 'text-green-600'
                        : 'text-gray-500'
                    }`}>
                      {step.title}
                    </span>
                  </button>
                  {index < steps.length - 1 && (
                    <div className={`flex-1 h-0.5 mx-4 ${
                      index < currentStep
                        ? 'bg-green-600'
                        : 'bg-gray-200'
                    }`} />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
        
        {renderStep()}
      </div>
    </div>
  );
}