import { create } from 'zustand';
import { User } from '../types';

interface AuthState {
  user: User | null;
  setUser: (user: User | null) => void;
  isAuthenticated: boolean;
  login: (role: 'employee' | 'hr' | 'admin') => void;
  logout: () => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isAuthenticated: false,
  setUser: (user) => set({ user, isAuthenticated: !!user }),
  login: (role) => set({
    user: {
      id: '1',
      email: `${role}@example.com`,
      firstName: role.charAt(0).toUpperCase() + role.slice(1),
      lastName: 'User',
      role: role,
      status: 'active'
    },
    isAuthenticated: true
  }),
  logout: () => set({ user: null, isAuthenticated: false })
}));