import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { DashboardLayout } from './components/layout/DashboardLayout';
import { OnboardingFlow } from './features/onboarding/OnboardingFlow';
import { EmployeeList } from './features/employees/EmployeeList';
import { EmployeeDetails } from './features/employees/EmployeeDetails';
import { UserList } from './features/admin/UserList';
import { UserManagement } from './features/admin/UserManagement';
import { DocumentList } from './features/documents/DocumentList';
import { DocumentViewer } from './features/documents/DocumentViewer';
import { DocumentManagement } from './features/documents/DocumentManagement';
import { Profile } from './features/profile/Profile';
import { useAuthStore } from './store/authStore';
import { Button } from './components/ui/Button';
import { UserPlus } from 'lucide-react';

function LoginPage() {
  const navigate = useNavigate();
  const { isAuthenticated, user, login } = useAuthStore();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md bg-white rounded-xl shadow-lg p-8 space-y-6">
        <h1 className="text-2xl font-bold text-center text-gray-900">Employee Portal</h1>
        <p className="text-center text-gray-600">Choose a role to continue:</p>
        <div className="space-y-3">
          <Button
            onClick={() => login('employee')}
            className="w-full"
          >
            Continue as Employee
          </Button>
          <Button
            onClick={() => login('hr')}
            className="w-full"
            variant="secondary"
          >
            Continue as HR
          </Button>
          <Button
            onClick={() => login('admin')}
            className="w-full"
            variant="outline"
          >
            Continue as Admin
          </Button>
        </div>
        
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-200"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">or</span>
          </div>
        </div>

        <Button
          onClick={() => navigate('/onboarding')}
          className="w-full"
          variant="outline"
        >
          <UserPlus className="w-4 h-4 mr-2" />
          Start Onboarding Process
        </Button>
        
        <p className="text-xs text-center text-gray-500">
          This is a demo application. Choose any role to explore the features.
        </p>
      </div>
    </div>
  );
}

function App() {
  const { isAuthenticated, user } = useAuthStore();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/onboarding" element={<OnboardingFlow />} />
        <Route path="/dashboard" element={<DashboardLayout />}>
          {/* Admin Routes */}
          {user?.role === 'admin' && (
            <>
              <Route index element={<UserList />} />
              <Route path="users" element={<UserList />} />
              <Route path="users/manage" element={<UserManagement />} />
            </>
          )}
          
          {/* HR Routes */}
          {user?.role === 'hr' && (
            <>
              <Route index element={<EmployeeList />} />
              <Route path="employees" element={<EmployeeList />} />
              <Route path="employees/:id" element={<EmployeeDetails />} />
              <Route path="documents/manage" element={<DocumentManagement />} />
            </>
          )}
          
          {/* Employee Routes */}
          {user?.role === 'employee' && (
            <>
              <Route index element={<Profile />} />
              <Route path="profile" element={<Profile />} />
              <Route path="documents" element={<DocumentList />} />
            </>
          )}

          {/* Shared Routes */}
          <Route path="documents/:id" element={<DocumentViewer />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;