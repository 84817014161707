export interface MockDocument {
  id: string;
  title: string;
  content: string;
  version: string;
  lastUpdated: string;
  status: 'pending' | 'signed';
  signedAt?: string;
}

export const mockDocuments: MockDocument[] = [
  {
    id: '1',
    title: 'Employee Handbook',
    content: `# Employee Handbook

## Introduction
Welcome to our company! This handbook contains important information about your employment.

## Company Values
1. Integrity
2. Innovation
3. Teamwork
4. Excellence

## Policies and Procedures
### Work Hours
Standard work hours are from 9:00 AM to 5:00 PM, Monday through Friday.

### Time Off
Employees are entitled to 20 days of paid time off annually.

### Code of Conduct
All employees are expected to maintain professional behavior and respect company policies.`,
    version: '2.1',
    lastUpdated: '2024-03-01',
    status: 'signed',
    signedAt: '2024-03-15'
  },
  {
    id: '2',
    title: 'Code of Conduct',
    content: `# Code of Conduct

## Purpose
This Code of Conduct outlines our expectations for employee behavior.

## Core Principles
1. Respect for Others
2. Professional Conduct
3. Ethical Decision Making
4. Compliance with Laws

## Guidelines
### Communication
- Maintain professional communication
- Respect confidentiality
- Use appropriate channels

### Workplace Behavior
- Treat others with respect
- Avoid conflicts of interest
- Report misconduct`,
    version: '1.5',
    lastUpdated: '2024-02-15',
    status: 'pending',
    signedAt: undefined
  },
  {
    id: '3',
    title: 'Benefits Overview',
    content: `# Benefits Overview

## Health Insurance
- Comprehensive medical coverage
- Dental and vision plans
- Family coverage options

## Retirement Benefits
- 401(k) plan
- Company matching
- Investment options

## Additional Benefits
- Life insurance
- Disability coverage
- Employee assistance program

## Wellness Programs
- Gym membership
- Mental health support
- Health screenings`,
    version: '3.0',
    lastUpdated: '2024-03-10',
    status: 'signed',
    signedAt: '2024-03-12'
  }
];